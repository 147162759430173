<template>
  <!-- logout START -->
  <div class="logout">
    <!-- logout:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- logout:loading END -->
  </div>
  <!-- logout START -->
</template>

<script>
export default {
  name: 'logout',
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Loading
       */
      loadingVisible: true,
      loadingHeadline: this.$t('loading.logout.headline'),
      loadingExcerpt: this.$t('loading.logout.excerpt'),
    };
  },
  created() {
    this.logoutUser();
  },
  methods: {
    logoutUser() {
      // clear cart
      if (this.$store.state.storeCart.items) {
        this.$store.dispatch('clearCart');
      }

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('lang'));
      // Logout data
      this.$http.post(`${process.env.VUE_APP_BASE_URL}logout`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');
            localStorage.removeItem('timeout');

            // saving encrypted token in cookie for server rendering
            this.$store.commit('SET_ETOKEN', null);
            localStorage.removeItem('eToken');

            if (localStorage.getItem('welcomeScreen')) {
              this.$router.push('/login');
            } else {
              this.$router.push('/');
            }
          } else {
            this.globalErrorMessage = true;
            this.resetForm.agbs = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            console.log(this.errorStatus);
          }
        });
    },
  },
};
</script>
